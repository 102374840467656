<nav class="navbar sticky-top navbar-expand-lg navbar-dark" style = "font-family: 'Asap', sans-serif; background-color: black;">
    <div class="container-fluid">
      <a class="navbar-brand" href="home">Terry Hoang Nguyen</a>
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav me-auto mb-2 mb-lg-0">
          <li class="nav-item">
            <a class="nav-link" aria-current="page" href="profile">Profile</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="projects">Projects</a>
          </li>
          <li class = "nav-item">
            <a class = "nav-link" href = "work">Work Experience</a>
          </li>
        </ul>
      </div>
    </div>
  </nav>