<div class = "container-fluid">
    <app-profile-banner id = "profile-banner"></app-profile-banner>
    <div class = "row">
        <div *ngIf = "innerWidth >= 1500;" class= "col-4 d-flex justify-content-center">
            <img id = "profile-pic" class = "rounded-circle zoom" src = "\assets\prof-pic.JPG" alt = "profile picture">
        </div>
        <div class = "col-8" id = "prof-desc">
                <div class = "container-fluid" style = "text-align:center;">
                    <div class= "row">
                        <div class = "d-flex justify-content-center">
                            <h2>About Me</h2>
                        </div> 
                    </div>
                    <div *ngIf = "innerWidth < 1500" class = "row">
                        <div class = "d-flex justify-content-center">
                            <img id = "profile-pic" class = "rounded-circle zoom" src = "\assets\prof-pic.JPG">
                        </div>
                    </div>
                    <br>
                    <div class= "row">
                        <div class = "d-flex justify-content-center">
                            <h4>Summary</h4>
                        </div>
                    </div>
                    <div class = "row">
                        <div class = "d-flex justify-content-center">
                            <p>4 years experience in programming with an emphasis on Python, but very open to other languages. Looking for opportunities in Software Development/ Game Development.</p>
                        </div>
                    </div>
                    <div class = "row">
                        <div class = "d-flex justify-content-center">
                            <h4>Contact Info</h4>
                        </div>
                    </div>

                    <div class = "row">
                        <div class = "d-flex justify-content-center">
                            <p>Student Email: terryhn1@uci.edu | Work Email: terryhoangnguyen@gmail.com | Linkedin: Check link below</p>
                        </div>
                    </div>
                    <div class= "row">
                        <div class = "d-flex justify-content-center">
                            <h4>Education</h4>
                        </div>
                    </div>
                    <div class = "row">
                        <div class = "d-flex justify-content-center">
                            <p>University of California - Irvine | Computer Science Major | Specialization in AI | Current GPA: 3.797 | Expected Grad: Spring 2022</p>
                        </div>
                    </div>
                    <div class = "row">
                        <div class= "d-flex justify-content-center">
                            <h4>Coursework</h4>
                        </div>
                    </div>
                    <div class = "row">
                        <div class = "d-flex justify-content-center">
                            <p>Data Structures, Machine Learning, Algorithm Graph Models, Computer Vision, Information Retrieval, Data Management, Computational Geometry, Human Computer Interaction</p>
                        </div>
                    </div>
                    <div class = "row">
                        <div class = "d-flex justify-content-center">
                            <h4>Hobbies and Interest</h4>
                        </div>
                    </div>

                    <div class = "row">
                        <div class = "d-flex justify-content-center">
                            <p>Volleyball, Basketball, Running, Photography, Hiking, Cooking, Music, Strategy Video Games</p>
                        </div>
                    </div>
                </div>
                <div class = "container-fluid" style = "text-align: center;">
                    <div class="row">
                        <div class = "justify-content-center d-flex">
                            <div class="col-3 zoom">
                                <a  href="https://github.com/terryhn1" target = "_blank">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="75" height="75" fill="black" class="bi bi-github" viewBox="0 0 16 16">
                                      <path d="M8 0C3.58 0 0 3.58 0 8c0 3.54 2.29 6.53 5.47 7.59.4.07.55-.17.55-.38 0-.19-.01-.82-.01-1.49-2.01.37-2.53-.49-2.69-.94-.09-.23-.48-.94-.82-1.13-.28-.15-.68-.52-.01-.53.63-.01 1.08.58 1.23.82.72 1.21 1.87.87 2.33.66.07-.52.28-.87.51-1.07-1.78-.2-3.64-.89-3.64-3.95 0-.87.31-1.59.82-2.15-.08-.2-.36-1.02.08-2.12 0 0 .67-.21 2.2.82.64-.18 1.32-.27 2-.27.68 0 1.36.09 2 .27 1.53-1.04 2.2-.82 2.2-.82.44 1.1.16 1.92.08 2.12.51.56.82 1.27.82 2.15 0 3.07-1.87 3.75-3.65 3.95.29.25.54.73.54 1.48 0 1.07-.01 1.93-.01 2.2 0 .21.15.46.55.38A8.012 8.012 0 0 0 16 8c0-4.42-3.58-8-8-8z"/>
                                    </svg>
                                </a>
                            </div>
                            <div style = "padding-left: 5px; padding-right:5px;"></div>
                            <div class = "col-3 zoom">
                                <a  href="https://www.linkedin.com/in/terryhoangnguyen/" target = "_blank">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="75" height="75" fill="currentColor" class="bi bi-linkedin" viewBox="0 0 16 16">
                                        <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4z"/>
                                    </svg>
                                </a>
                            </div>
                        </div>  
                    </div>
                </div>
            <br>
        </div>
    </div>
    <div id = "half" style = "padding: 2%;"></div>
    <div id = "icon-translate">
        <div *ngFor = "let row of data">
            <div class = "d-flex justify-content-center" style = "padding-bottom: 1%; text-align: center;">
                <h2>
                    {{row.title}}
                </h2>
            </div>
            <div class = "container">
                <div class = "row d-flex justify-content-center icon-t" style = "padding-bottom: 3%;">
                    <img *ngFor = "let image of row.images; trackBy: trackImage" src = {{image}} id = "languages" class = "zoom">
                </div>
            </div>
        </div>
    </div>
</div>
<div id = "end"></div>


