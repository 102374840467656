<div style = "padding: 2vh"></div>
<div class="container-fluid profile">
    <div class = "row">
        <div class = "justify-content-center d-flex">
            <div class="squares square-normal"></div>
            <div class="squares square-normal"></div>
            <div class="squares square-normal"></div>

            <div class="squares square-blank"></div>

            <div class="squares square-normal"></div>
            <div class="squares square-normal"></div>
            <div class="squares square-normal"></div>

            <div class="squares square-blank"></div>

            <div class="squares square-normal"></div>
            <div class="squares square-normal"></div>
            <div class="squares square-normal"></div>

            <div class="squares square-blank"></div>

            <div class="squares square-normal"></div>
            <div class="squares square-blank"></div>
            <div class="squares square-blank"></div>

            <div class="squares square-blank"></div>

            <div class="squares square-normal"></div>
            <div class="squares square-normal"></div>
            <div class="squares square-normal"></div>

            <div class="squares square-blank"></div>

            <div class="squares square-normal"></div>
            <div class="squares square-normal"></div>
            <div class="squares square-normal"></div>

            <div class="squares square-blank"></div>

            <div class="squares square-normal"></div>
            <div class="squares square-normal"></div>
            <div class="squares square-normal"></div>
        </div>
    </div>
        <div class = "row">
            <div class = "justify-content-center d-flex">
                <div class="squares square-normal"></div>
                <div class="squares square-blank"></div>
                <div class="squares square-normal"></div>

                <div class="squares square-blank"></div>

                <div class="squares square-normal"></div>
                <div class="squares square-blank"></div>
                <div class="squares square-normal"></div>

                <div class="squares square-blank"></div>

                <div class="squares square-normal"></div>
                <div class="squares square-blank"></div>
                <div class="squares square-normal"></div>

                <div class="squares square-blank"></div>

                <div class="squares square-normal"></div>
                <div class="squares square-blank"></div>
                <div class="squares square-blank"></div>

                <div class="squares square-blank"></div>

                <div class="squares square-normal"></div>
                <div class="squares square-blank"></div>
                <div class="squares square-blank"></div>

                <div class="squares square-blank"></div>

                <div class="squares square-normal"></div>
                <div class="squares square-blank"></div>
                <div class="squares square-blank"></div>

                <div class="squares square-blank"></div>

                <div class="squares square-blank"></div>
                <div class="squares square-normal"></div>
                <div class="squares square-blank"></div>
            </div>
        </div>
        <div class = "row">
            <div class = "justify-content-center d-flex">
                <div class="squares square-normal"></div>
                <div class="squares square-normal"></div>
                <div class="squares square-normal"></div>

                <div class="squares square-blank"></div>

                <div class="squares square-normal"></div>
                <div class="squares square-normal"></div>
                <div class="squares square-normal"></div>

                <div class="squares square-blank"></div>

                <div class="squares square-normal"></div>
                <div class="squares square-blank"></div>
                <div class="squares square-normal"></div>

                <div class="squares square-blank"></div>

                <div class="squares square-normal"></div>
                <div class="squares square-blank"></div>
                <div class="squares square-blank"></div>

                <div class="squares square-blank"></div>

                <div class="squares square-normal"></div>
                <div class="squares square-normal"></div>
                <div class="squares square-normal"></div>

                <div class="squares square-blank"></div>

                <div class="squares square-normal"></div>
                <div class="squares square-blank"></div>
                <div class="squares square-blank"></div>

                <div class="squares square-blank"></div>

                <div class="squares square-blank"></div>
                <div class="squares square-normal"></div>
                <div class="squares square-blank"></div>
            </div>
        </div>
        <div class = "row">
            <div class = "justify-content-center d-flex">
                <div class="squares square-normal"></div>
                <div class="squares square-blank"></div>
                <div class="squares square-blank"></div>

                <div class="squares square-blank"></div>

                <div class="squares square-normal"></div>
                <div class="squares square-normal"></div>
                <div class="squares square-blank"></div>

                <div class="squares square-blank"></div>

                <div class="squares square-normal"></div>
                <div class="squares square-blank"></div>
                <div class="squares square-normal"></div>

                <div class="squares square-blank"></div>

                <div class="squares square-normal"></div>
                <div class="squares square-blank"></div>
                <div class="squares square-normal"></div>

                <div class="squares square-blank"></div>

                <div class="squares square-normal"></div>
                <div class="squares square-blank"></div>
                <div class="squares square-blank"></div>

                <div class="squares square-blank"></div>

                <div class="squares square-normal"></div>
                <div class="squares square-blank"></div>
                <div class="squares square-blank"></div>

                <div class="squares square-blank"></div>

                <div class="squares square-blank"></div>
                <div class="squares square-normal"></div>
                <div class="squares square-blank"></div>
            </div>
        </div>
        <div class = "row">
            <div class = "justify-content-center d-flex">
                <div class="squares square-normal"></div>
                <div class="squares square-blank"></div>
                <div class="squares square-blank"></div>

                <div class="squares square-blank"></div>

                <div class="squares square-normal"></div>
                <div class="squares square-blank"></div>
                <div class="squares square-normal"></div>

                <div class="squares square-blank"></div>

                <div class="squares square-normal"></div>
                <div class="squares square-normal"></div>
                <div class="squares square-normal"></div>

                <div class="squares square-blank"></div>

                <div class="squares square-normal"></div>
                <div class="squares square-normal"></div>
                <div class="squares square-normal"></div>

                <div class="squares square-blank"></div>

                <div class="squares square-normal"></div>
                <div class="squares square-normal"></div>
                <div class="squares square-normal"></div>

                <div class="squares square-blank"></div>

                <div class="squares square-normal"></div>
                <div class="squares square-normal"></div>
                <div class="squares square-normal"></div>

                <div class="squares square-blank"></div>

                <div class="squares square-blank"></div>
                <div class="squares square-normal"></div>
                <div class="squares square-blank"></div>
            </div>
        </div>
</div>
<div style = "padding: 3vh"></div>
